.MarketView {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    width: 100%;
}

.MarketView-Controller {
    flex: 1;
}

.MarketView-Display {
    flex: 3;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.MarketView-Display .container {
    flex: 1;
    overflow: hidden;
    border: solid gray 1px;
    box-sizing: content-box;
}

.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Toolbox {
    display: grid;
    grid-template-columns: repeat(auto-fill, 5em);
    grid-auto-rows: 5em;
}

.Toolbox > div {
}
